<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            type="none" 
            name="plantCd" 
            v-model="searchParam.plantCd" 
            @datachange="datachange" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
            @datachange="datachange"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table1"
          title="대분류"
          tableId="ctoChecklist1"
          :columns="grid1.columns"
          :editable="editable"
          :filtering="true"
          :columnSetting="false"
          :usePaging="false"
          :hideBottom="false"
          :isFullScreen="false"
          :data="grid1.data"
          rowKey="checklistMasterId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                label="엑셀업로드"
                icon="upload"
                @btnClicked="excelUploadData" />
              <c-btn 
                v-if="editable" 
                label="추가" 
                icon="add_circle" 
                @btnClicked="add('FST')" />
              <c-btn
                v-show="editable && grid1.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave1"
                :param="grid1.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveChecklist('FST')"
                @btnCallback="(result) => saveCallback(result, 'FST')" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-chip
                v-if="props.row.editFlag !== 'C'"
                outline square
                color="blue"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="rowClick(props.row)">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table2"
          :title="`[${checklistName}] 중분류`"
          tableId="ctoChecklist2"
          :filtering="true"
          :columnSetting="false"
          :usePaging="false"
          :hideBottom="false"
          :isFullScreen="false"
          :columns="grid2.columns"
          :editable="editable"
          :data="grid2.data"
          rowKey="checklistMasterId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn 
                v-if="editable && isSelect" 
                label="추가" 
                icon="add_circle" 
                @btnClicked="add('SEC')" />
              <c-btn
                v-show="editable && isSelect && grid2.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave2"
                :param="grid2.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveChecklist('SEC')"
                @btnCallback="(result) => saveCallback(result, 'SEC')" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'cto-checklist-master',
  data() {
    return {
      popupOptions: {
        isFull: false,
        width: '40%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
      searchParam: {
        plantCd: null,
        checklistLevelCd: '1',
        useFlag: 'Y',
      },
      grid1: {
        columns: [
          {
            required: true,
            name: 'checklistName',
            field: 'checklistName',
            style: 'width:300px',
            type: 'text',
            label: '항목명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '정렬순서',
            align: 'center',
            style: 'width:80px',
            type: 'number',
            sortable: false
          },
          {
            name: "useFlag",
            field: "useFlag",
            label: "사용여부",
            align: "center",
            style: 'width:80px',
            type: 'check',
            sortable: false,
            true: 'Y',
            false: 'N'
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '중분류',
            align: 'center',
            style: 'width:70px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            required: true,
            name: 'checklistName',
            field: 'checklistName',
            label: '항목명',
            type: 'text',
            align: 'left',
            sortable: true,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '정렬순서',
            align: 'center',
            style: 'width:80px',
            type: 'number',
            sortable: false
          },
          {
            name: "useFlag",
            field: "useFlag",
            label: "사용여부",
            align: "center",
            style: 'width:80px',
            type: 'check',
            sortable: false,
            true: 'Y',
            false: 'N'
          },
        ],
        data: [],
      },
      useFlagItems: [],
      select: {
        checklistMasterId: '',
        checklistName: '',
      },
      isSave1: false,
      isSave2: false,
      editable: true,
      listUrl: '',
      saveUrl: '',
    };
  },
  computed: {
    isSelect() {
      return Boolean(this.select.checklistMasterId)
    },
    checklistName() {
      return this.select.checklistName ? this.select.checklistName : '선택 X'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.cto.stdchecklist.list.url
      this.deleteUrl = transactionConfig.sop.cto.stdchecklist.delete.url;
      this.saveUrl = transactionConfig.sop.cto.stdchecklist.save.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
        this.grid2.data = []
      },);
    },
    datachange() {
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    rowClick(row) {
      this.$set(this.$data, 'select', row)
      this.underList();
    },
    underList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        parentChecklistMasterId: this.select.checklistMasterId,
        useFlag: 'Y',
        checklistLevelCd: '2',
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
    add(type) {
      if (type === 'FST') {
        this.grid1.data.splice(0, 0, {
          checklistMasterId: uid(),
          checklistName: '',
          plantCd: this.searchParam.plantCd,
          checklistLevelCd: '1',
          parentChecklistMasterId: '',
          useFlag: 'Y',
          sortOrder: this.grid1.data.length + 1,
          regUserId: this.$store.getters.user.userId,
          editFlag: 'C'
        })
      } else {
        this.grid2.data.splice(0, 0, {
          checklistMasterId: uid(),
          checklistName: '',
          plantCd: this.searchParam.plantCd,
          checklistLevelCd: '2',
          parentChecklistMasterId: this.select.checklistMasterId,
          useFlag: 'Y',
          sortOrder: this.grid2.data.length + 1,
          regUserId: this.$store.getters.user.userId,
          editFlag: 'C'
        })
      }
    },
    saveChecklist(type) {
      let validItem = (type === 'FST' ? this.grid1.data : this.grid2.data)
      let validColumns = (type === 'FST' ? this.grid1.columns : this.grid2.columns)
      if (this.$comm.validTable(validColumns, validItem)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (type === 'FST') {
              this.isSave1 = !this.isSave1;
            } else {
              this.isSave2 = !this.isSave2
            } 
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback(_result, type) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (type === 'FST') {
        this.getList();
      } else {
        this.underList();
      }
    },
    excelUploadData() {
      this.popupOptions.title = '행동관찰 체크리스트 업로드';
      this.popupOptions.target = () => import(`${'./ctoChecklistMasterExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        let mst_data = [];
        let mst_data_targetName = [];
        this.$_.forEach(s_data, item => {
          item.useFlag = 'Y';
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          if (this.$_.indexOf(mst_data_targetName, item.plantCd + item.mchecklistName) < 0) {
            mst_data_targetName.push(item.plantCd + item.mchecklistName)
            mst_data.push({
              checklistMasterId: uid(),
              checklistName: item.mchecklistName,
              plantCd: item.plantCd,
              checklistLevelCd: '1',
              parentChecklistMasterId: '',
              useFlag: 'Y',
              sortOrder: item.msortOrder,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
              items: [],
            })
          }
        })
        this.$_.forEach(mst_data, _mst => {
          this.$_.forEach(s_data, item => {
            if ((_mst.plantCd + _mst.checklistName) == (item.plantCd + item.mchecklistName)) {
              item.useFlag = 'Y';
              item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
              _mst.items.push({
                checklistMasterId: uid(),
                checklistName: item.checklistName,
                plantCd: item.plantCd,
                checklistLevelCd: '2',
                parentChecklistMasterId: '',
                useFlag: 'Y',
                sortOrder: item.sortOrder,
                regUserId: this.$store.getters.user.userId,
                editFlag: 'C'
              })
            }
          })
        })
        this.$http.url = this.saveUrl + '/excel';
        this.$http.type = 'POST';
        this.$http.param = mst_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    }
  }
};
</script>
